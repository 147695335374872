import { REDUCE_MOTION } from '@/constants';
import { onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
/**
 *
 * Composable used by components to indicate they want to use the full screen width.
 *
 * If REDUCE_MOTION is not enabled (i.e. we have animations enabled), `fullWidth` is
 * set to false when the screen transition ends, to avoid seeing the screen shrink on
 * screen changes. See `unsetFullWidth`
 */
export function useFullWidth() {
    const store = useStore();
    onMounted(() => store.commit('setFullWidth', true));
    onUnmounted(() => {
        if (REDUCE_MOTION) {
            store.commit('setFullWidth', false);
        }
    });
}
